<template>
    <CardComponent class="cash-card">
        <!-- <div class="cash-card"> -->
        <div class="cash-card-main" v-if="data.length > 0">
            <div class="left">
                <p>
                    <i style="color: orange" :class="icon"></i>
                    {{ itemTitle }}
                </p>
                <p>
                    <span>￥</span>
                    <span>{{ totalAmount }}</span>
                </p>
                <p class="date">{{ date }}</p>
            </div>
            <div class="right">
                <el-scrollbar style="height: 100%">
                    <div
                        v-for="(item, index) in data"
                        :key="index"
                        class="cash-item"
                    >
                        <span>{{ item.title }}</span>
                        <span>￥{{ item.value }}</span>
                    </div>
                </el-scrollbar>
            </div>
        </div>
        <Empty v-else :showImg="false" />
        <!-- </div> -->
    </CardComponent>
</template>

<script>
import CardComponent from '../components/card'
import Empty from '@/components/empty'
export default {
    name: 'CashCard',
    components: {
        CardComponent,
        Empty,
    },
    props: {
        date: {
            type: String,
            default: '',
        },
        itemTitle: {
            type: String,
            default: '现金余额',
        },
        totalAmount: {
            type: String,
            default: null,
        },
        data: {
            type: Array,
            default: () => {
                return []
            },
        },
        icon: {
            type: String,
            default: null,
        },
    },
    data() {
        return {}
    },
    created() {},
    methods: {},
}
</script>
<style lang="scss" scoped>
.cash-card {
    display: flex;
    width: 100%;
    height: 100%;
    ::v-deep .main {
        width: 100%;
        padding: 0;
        .cash-card-main {
            display: flex;
            width: 100%;
            margin-top: .2rem;
            .left {
                width: 40%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 0 0 0.2rem;
                box-sizing: border-box;
                p {
                    width: 100%;
                    font-size: 0.18rem;
                    font-weight: 500;
                    text-align: left;
                }
                > p:nth-child(1) {
                    color: #909399;
                }
                > p:nth-child(2) {
                    color: #54636f;
                }
                p.date {
                    height: 0.24rem;
                    font-size: 0.14rem;
                    color: #909399;
                    font-weight: 400;
                    box-sizing: border-box;
                    padding-left: .05rem;
                }
            }
            .right {
                height: 1.56rem;
                flex: 1 !important;
                padding: 0.1rem;
                box-sizing: border-box;
            }
            .el-scrollbar__view {
                // width: 1.8rem;
            }
            .el-scrollbar {
                width: 100%;
            }
            .el-scrollbar__wrap {
                overflow-x: hidden !important;
            }
            .cash-item {
                display: flex;
                justify-content: space-between;
                padding-right: 0.2rem;
                line-height: 0.28rem;
                box-sizing: border-box;
                width: 2rem;
                > span:nth-child(1) {
                    width: 50%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 0.14rem;
                    color: #909399;
                    font-weight: 400;
                }
                > span:nth-child(2) {
                    flex: 1;
                    // width: .96rem;
                    text-align: right;
                    font-size: 0.14rem;
                    color: #54636f;
                    font-weight: 500;
                }
            }
        }
    }
}
</style>
